<template>
  <div class="identity-template q-w360 q-fit">
    <h2 class="identity-title identity-template__title u-typography-helvetica u-text u-text--xs">
      {{ $t('prefinalPage.introText') }}
    </h2>

    <question-with-error-wrap class="q-mt32" :error="getFieldsError(['lastName'])">
      <u-input
        :value="lastName"
        :label="$t('prefinalPage.label.lastName')"
        @input="onFieldChange('lastName', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap class="q-mt16" :error="getFieldsError(['phone'])">
      <u-phone-input
        class="identity-phone-input"
        :value="phoneValue"
        :countries="phoneCountries"
        :label="$t('prefinalPage.label.phoneNumber')"
        @input="onPhoneChange"
      >
        <template #value="{ value: phonePrefix }">
          <span class="identity-phone-input__value">
            <span class="q-mr8">{{ phonePrefix }}</span>
            <span class="flag-icon" :class="`flag-icon-${phoneIsoCode.toLowerCase()}`" />
          </span>
        </template>

        <template #option="{ option: { countryCode, phonePrefix } }">
          <span class="identity-phone-input__country-option country-option">
            <span class="flag-icon q-mr8" :class="`flag-icon-${countryCode.toLowerCase()}`" />
            <span class="country-option__country-name q-mr8">{{
              getCountryDisplayName(countryCode, { maxLength: 20 })
            }}</span>
            <span class="country-option__prefix">({{ phonePrefix }})</span>
          </span>
        </template>
      </u-phone-input>
    </question-with-error-wrap>

    <u-checkbox
      class="identity-template__checkbox q-mt16"
      :value="smsAccepted"
      :label="$t('consent.agreeForCarePrescription')"
      :disabled="!phone"
      @input="onFieldChange('smsAccepted', $event)"
    />

    <u-checkbox
      class="identity-template__checkbox q-mt16 q-mb32"
      :value="marketingSmsOffersAccepted"
      :label="$t('consent.agreeForOffers')"
      :disabled="!phone"
      @input="onFieldChange('marketingSmsOffersAccepted', $event)"
    />
  </div>
</template>

<script>
import { UInput, UPhoneInput, UCheckbox } from 'universkin-shared';

import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';
import { displayNameMixin } from '@/mixins/displayNameMixin';

export default {
  name: 'IdentityTemplate',
  components: { QuestionWithErrorWrap, UInput, UPhoneInput, UCheckbox },
  mixins: [stepTemplateMixin, displayNameMixin],
  props: {
    lastName: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    },
    phoneIsoCode: {
      type: String,
      default: ''
    },
    smsAccepted: {
      type: Boolean,
      default: false
    },
    marketingSmsOffersAccepted: {
      type: Boolean,
      default: false
    },
    phoneCountries: {
      type: Array,
      required: true
    }
  },
  computed: {
    phoneValue() {
      return {
        number: this.phone,
        countryCode: this.phoneIsoCode
      };
    }
  },
  methods: {
    onPhoneChange({ number, countryCode }) {
      if (this.phoneIsoCode !== countryCode) {
        this.onFieldChange('phoneIsoCode', countryCode);

        return;
      }

      this.onFieldChange('phone', number);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';
@import '~@/assets/css/questionnaire/flags.css';

.identity-template {
  &__title {
    margin-top: 16px;
  }

  &__checkbox {
    font-family: var(--u-font-family-Gilroy);
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
}

.identity-phone-input {
  &__value,
  &__country-option {
    display: flex;
    align-items: center;
  }
}

.identity-title {
  text-align: center;
  color: var(--u-color-grey-600);
}

.country-option {
  &__country-name {
    white-space: nowrap;
  }
}

@media (min-width: $desktop-start) {
  .identity-template {
    &__title {
      margin-top: 32px;
    }
  }
}
</style>
