<template>
  <identity-template
    v-if="isLoaded"
    :last-name="lastName"
    :phone="phone"
    :phone-iso-code="patientPhoneIsoCode"
    :sms-accepted="smsAccepted"
    :marketing-sms-offers-accepted="marketingSmsOffersAccepted"
    :phone-countries="$options.phoneCountries"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import IdentityTemplate from '@/modules/questionnaire/components/steps/common/identity/identity/IdentityTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import rootTypes from '@/store/types';
import { types } from '@/modules/questionnaire/store/types';
import { SORTED_PHONE_CODES_BY_COUNTRY_NAME } from '@/constants';

const { field } = fieldBuilder;

const FIELDS_TO_RESET = ['smsAccepted', 'marketingSmsOffersAccepted'];

const FIELDS = [
  field('lastName'),
  field('phone'),
  field('phoneIsoCode'),
  ...FIELDS_TO_RESET.map(field)
];

export default {
  name: 'Identity',
  components: {
    IdentityTemplate
  },
  phoneCountries: SORTED_PHONE_CODES_BY_COUNTRY_NAME,
  mixins: [makeStep(FIELDS), resetStoreValues],
  data() {
    return {
      isLoaded: false
    };
  },
  computed: {
    ...mapGetters({
      questionnaireLocale: rootTypes.getters.GET_LOCALE,
      doctor: types.getters.GET_DOCTOR,
      patient: types.getters.GET_PATIENT
    }),
    patientPhoneIsoCode() {
      return this.phoneIsoCode || this.defaultPhoneIsoCode;
    },
    patientSmsAccepted() {
      return this.smsAccepted;
    },
    defaultPhoneIsoCode() {
      return this.doctor.country.toLowerCase();
    }
  },
  watch: {
    phone(newValue) {
      if (!newValue) {
        this.resetStoreFieldsByName(FIELDS_TO_RESET);
      }
    }
  },
  async created() {
    await this.fetchPatient({ email: this.patient.email });

    const { lastName } = this.patient;

    if (lastName) {
      this.lastName = lastName;
    }

    if (!this.phoneIsoCode) {
      this.phoneIsoCode = this.defaultPhoneIsoCode;
    }

    const { locale } = this.patient;

    if (this.questionnaireLocale !== locale) {
      await this.updatePatient({ locale: this.questionnaireLocale });
    }

    this.isLoaded = true;
  },
  methods: {
    ...mapActions({
      fetchPatient: types.actions.FETCH_PATIENT,
      updatePatient: types.actions.UPDATE_PATIENT
    })
  }
};
</script>
